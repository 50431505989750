import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/solid'
import projects from "./project-data.js";

export default function ProjectModal({ projectIdx, open, setOpen }) {
    const [project, setProject] = useState(projects[projectIdx])
    const cancelButtonRef = useRef(null)

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white max-h-[90vh] text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                            <div className="bg-white">
                                <div className='relative'>
                                    <XMarkIcon className='absolute h-6 right-1 top-1 cursor-pointer text-white' onClick={() => setOpen(false)} ref={cancelButtonRef} />
                                    <img
                                        src={project.image}
                                        alt={project.alt}
                                        className="h-full w-full object-cover object-center group-hover:opacity-75 max-h-[75vh]"
                                    />
                                </div>
                                <div className="sm:flex sm:items-start py-2">
                                    <div className="w-[93%] mt-3 text-center ml-4 mt-0 text-left">
                                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900 text-left">
                                            {project.title}
                                        </Dialog.Title>
                                        <div className="mt-1">
                                            {project.dimensions && project.info &&
                                                <>
                                                    <div className='w-full flex justify-between'>
                                                        <p className="text-sm text-gray-400">{project.description}</p>
                                                        <p className="text-sm text-gray-400 text-right">{project.dimensions}</p>
                                                    </div>
                                                    <p className="text-sm text-gray-400">{project.info}</p>
                                                </>
                                            }
                                            {project.dimensions &&
                                                <div className='w-full flex justify-between'>
                                                    <p className="text-sm text-gray-400">{project.description}</p>
                                                    <p className="text-sm text-gray-400 text-right">{project.dimensions}</p>
                                                </div>
                                            }
                                            {project.info &&
                                                <div className='w-full flex justify-between'>
                                                    <p className="text-sm text-gray-400">{project.description}</p>
                                                    <p className="text-sm text-gray-400 text-right">{project.info}</p>
                                                </div>
                                            }
                                            {!project.dimensions && !project.info &&
                                                <p className="text-sm text-gray-400 text-left">{project.description}</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
            </Dialog>
        </Transition.Root>
    )
}
