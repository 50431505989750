import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import "./Contact.css";

function Contact() {
  let [senderName, setSenderName] = useState("");
  let [senderEmail, setSenderEmail] = useState("");
  let [senderPhone, setSenderPhone] = useState("");
  let [message, setMessage] = useState("");

  function updateTextState(event) {
    switch (event.target.name) {
      case "sender_name":
        setSenderName(event.target.value);
        break;
      case "sender_email":
        setSenderEmail(event.target.value);
        break;
      case "sender_phone":
        setSenderPhone(event.target.value);
        break;
      default:
        setMessage(event.target.value);
        break;
    }
  }

  let contactFormInputs = {
    sender_name: senderName,
    sender_email: senderEmail,
    sender_phone: senderPhone,
    message: message,
  };

  function handleSubmit(event) {
    event.preventDefault();
    const submitFeedbackField = document.querySelector(".sendFeedback");
    if (
      senderName !== "" &&
      senderEmail !== "" &&
      senderPhone !== "" &&
      message !== ""
    ) {
      emailjs
        .send(
          "artsing8",
          "artsing8_template",
          contactFormInputs,
          "-nVgwSSo7LJY0IG8O"
        )
        .then(
          (response) => {
            console.log("Email sent.", response.status, response.text);
            submitFeedbackField.classList.remove("messageProblem");
            submitFeedbackField.classList.add("messageSent");
            submitFeedbackField.textContent = "Your message was sent.";
          },
          (err) => {
            console.log("Something went wrong...", err);
            submitFeedbackField.classList.remove("messageSent");
            submitFeedbackField.classList.add("messageProblem");
            submitFeedbackField.textContent = "Something went wrong...";
          }
        );
    } else {
      submitFeedbackField.classList.remove("messageSent");
      submitFeedbackField.classList.add("messageProblem");
      submitFeedbackField.textContent = "Please complete all required fields.";
    }
  }

  return (
    <div className="flex flex-col items-center rounded-sm w-[70%] m-8 z-1">
      <h3 className="contactFormTitle m-2">Contact Me</h3>
      <h6>
        <a href="mailto:artsing8@gmail.com" className="contactEmail m-1">
          artsing8@gmail.com
        </a>
      </h6>
      <h6 className="contactPhone m-1">(520) 850-6251</h6>
      <form className="contactForm mt-4 p-1" onSubmit={handleSubmit}>
        <div className="formGroup">
          <label htmlFor="exampleFormControlInput1">Your Name</label>
          <input
            type="text"
            className="form-control nameInput"
            placeholder="First Last"
            name="sender_name"
            onChange={updateTextState}
          ></input>

          <p className="feedbackText nameFeedback"></p>
        </div>
        <div className="formGroup">
          <label htmlFor="exampleFormControlInput1">Your Email</label>
          <input
            type="email"
            className="form-control emailInput"
            placeholder="name@example.com"
            name="sender_email"
            onChange={updateTextState}
          ></input>
          <p className="feedbackText emailFeedback"></p>
        </div>
        <div className="formGroup">
          <label htmlFor="exampleFormControlInput1">Your Phone Number</label>
          <input
            type="phone"
            className="form-control phoneInput"
            placeholder="000-000-0000"
            name="sender_phone"
            onChange={updateTextState}
          ></input>
          <p className="feedbackText phoneFeedback"></p>
        </div>
        <div className="formGroup">
          <label htmlFor="exampleFormControlTextarea1">Your Message</label>
          <textarea
            className="form-control messageInput"
            rows="3"
            name="message"
            onChange={updateTextState}
          ></textarea>
          <p className="feedbackText messageFeedback"></p>
        </div>
        <button type="submit" className="custom-btn" aria-label="send email">
          Send Email
        </button>
        <p className="sendFeedback"></p>
      </form>
    </div>
  );
}

export default Contact;
