import "./Footer.css";

function Footer() {
  return (
    <div className="footerDiv">
      <p>
        This site was made with ❤️ by{" "}
        <a
          href="https://www.linkedin.com/in/becca-roberts/"
          target="_blank"
          rel="noreferrer"
          className="footerLink"
        >
          Becca Roberts
        </a>
      </p>
    </div>
  );
}

export default Footer;
