import React from "react";
import "./About.css";
import photo from "../Gallery/images/img_4749.jpg";

function About() {
  return (
    <div className="aboutContainer mt-2">
      <img
        src={photo}
        alt="Candace with her granddaughter"
        className="aboutImg"
      />
      <div>
        <p className="aboutText">
          A wisdom awakens in nature’s life cycle of beauty and decay.
        </p>
        <p className="aboutText">
          I recreate leaves, flowers, woven webs, branches and nests in bronze,
          to meditate on the wonders of the natural world.
        </p>
      </div>
    </div>
  );
}

export default About;
