import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";

import BannerAnnouncement from "./components/BannerAnnouncement/BannerAnnouncement";
import HeaderNav from "./components/HeaderNav/HeaderNav.js";
import Footer from "./components/Footer/Footer.js";
import About from "./components/About/About.js";
// import Gallery from "./components/Gallery/Gallery.js";
import GalleryV2 from "./components/Gallery/Gallery_v2.js";
import Contact from "./components/Contact/Contact.js";

function App() {
  const [hideMessage, setHideMessage] = useState(true)

  // remove stone labrinth banner after
  useEffect(() => {
    const today = new Date();
    const cutoff = new Date('04/27/2024');
    if (today < cutoff) {
      setHideMessage(false);
    } else {
      console.log('cutoff date for banner has passed!')
    }
  },[])

  useEffect(() => {
    console.log('hideMessage', hideMessage)
  },[hideMessage])

  return (
    <>
      <HeaderNav />
      {!hideMessage &&
        <BannerAnnouncement 
          message="Meet, greet, and join me in the making of a stone labrinth on Saturday, April 27, 2024."
          url="https://sculpture.org/events/EventDetails.aspx?id=1847348&group=254797"
          setHideMessage={setHideMessage}
        />
      }
      <div className="flex justify-center items-start min-h-[90vh]">
        <Routes>
          <Route path="/" element={<About />} />
          <Route path="/gallery" element={<GalleryV2 />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
      <Footer />
    </>
  );
}

export default App;
