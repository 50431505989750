import { useState } from "react";
import ProjectModal from "./ProjectModal.js";
import projects from "./project-data.js";

export default function GalleryV2() {
  const [showOneProject, setShowOneProject] = useState(false);
  const [projectIndex, setProjectIndex] = useState(0);

  const handleShowProject = (e) => {
    setProjectIndex(e.target.parentNode.getAttribute("data-index"));
    setShowOneProject(true);
  };

  return (
    <div className="bg-[#dbd8d0] w-full px-6">
      {showOneProject ? <ProjectModal projectIdx={projectIndex} open={showOneProject} setOpen={setShowOneProject} /> : null}
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        <h2 className="sr-only">Projects</h2>

        <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
          {projects.map((p, idx) => (
            <div key={idx} data-index={idx} onClick={(e) => handleShowProject(e)} className="group cursor-pointer">
              <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                <img
                  src={p.image}
                  alt={p.alt}
                  className="h-full w-full object-cover object-center group-hover:opacity-75"
                />
              </div>
              <p className="mt-4 text-sm font-medium text-gray-900">{p.title}</p>
              <p className="mt-1 text-xs text-gray-700">{p.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
