import { XMarkIcon } from "@heroicons/react/24/outline"

export default function BannerAnnouncement({message, url, setHideMessage}) {

    return (
        <div className="bg-gradient-to-r from-[#5d4923] to-[#3C4B39] min-h-16 flex justify-center w-full">
            <div className="flex justify-between items-center w-full text-xl">
                <XMarkIcon 
                    onClick={() => setHideMessage(true)} 
                    className="text-[#e1e1d8] hover:text-white cursor-pointer text-center h-10 p-2"
                />
                <div className="flex justify-center">
                    <a href={url} target="_blank" className="flex justify-center items-center">
                        <p className="text-[#e1e1d8] text-center p-2">{message}</p>
                    </a>
                </div>
                <div></div>
            </div>
        </div>
    )
}