// photo additions from 3/24/24::
import pyramid_stone_stacks_2 from './images/pyramid_stone_stacks_2.jpeg';
import pyramid_stone_stack_1 from './images/pyramid_stone_stack_1.jpeg';
import sculpture_fest_1 from './images/sculpture_fest_1.jpeg';
import sculpture_fest_2 from './images/sculpture_fest_2.jpeg';
import leaves_1 from './images/leaves_1.jpeg';
import leaves_2 from './images/leaves_2.jpeg';
// original photo list:
import img_4749 from "./images/img_4749.jpg";
import img_4751 from "./images/img_4751.jpg";
import img_4755 from "./images/img_4755.jpg";
import img_4756 from "./images/img_4756.jpg";
import img_4761 from "./images/img_4761.jpg";
import img_4763 from "./images/img_4763.jpg";
import img_4764 from "./images/img_4764.jpg";
import img_4765 from "./images/img_4765.jpg";
import img_4767 from "./images/img_4767.jpg";
import img_4770 from "./images/img_4770.jpg";

const projects = [
  // added 3/24/24
  {
    image: pyramid_stone_stacks_2,
    alt: "two outdoor sculptures featuring bronze pyramid bases with stacked round stones on top",
    title: "Balanced Stones",
    description: "Steel & Stone",
    info: null,
    dimensions: "2' base | 4' total height"
  },
  {
    image: pyramid_stone_stack_1,
    alt: "outdoor sculpture featuring bronze pyramid base with stacked round stones on top",
    title: "Balanced Stones",
    description: "Steel & Stone",
    info: "Client Setting",
    dimensions: null
  },
  {
    image: sculpture_fest_1,
    alt: "bronze and stone sculptures on display at the 2024 Tucson Sculpture Festival",
    title: "Sculpture Tucson Show",
    description: "Bronze, Steel, & Stone",
    info: "3/14/2024",
    dimensions: null
  },
  {
    image: sculpture_fest_2,
    alt: "bronze and stone sculptures on display at the 2024 Tucson Sculpture Festival",
    title: "Sculpture Tucson Show",
    description: "Bronze, Steel, & Stone",
    info: "3/14/2024",
    dimensions: null
  },
  {
    image: leaves_1,
    alt: "sculpture of a cluster of leaves cast in bronze",
    title: "Leaves Spread Across a Canopy of Dreams",
    description: "Bronze",
    info: null,
    dimensions: null
  },
  {
    image: leaves_2,
    alt: "sculpture of a cluster of leaves cast in bronze",
    title: "Leaves Spread Across a Canopy of Dreams",
    description: "Bronze",
    info: null,
    dimensions: null
  },
  // original photo list...
  {
    image: img_4749,
    alt: "sculpture of a cluster of leaves cast in bronze",
    title: "Waterleaf Gaze",
    description: "Bronze",
    info: null,
    dimensions: "12in x 10in x 6in"
  },
  {
    image: img_4751,
    alt: "botanic sculpture cast in bronze",
    title: "Rivered in Leafshed",
    description: "Bronze",
    info: null,
    dimensions: "14in x 9in x 8in"
  },
  {
    image: img_4755,
    alt: "botanic sculpture cast in bronze",
    title: "Lightfold Returns the Singing Earth",
    description: "Bronze",
    info: null,
    dimensions: "11in x 9in x 8in"
  },
  {
    image: img_4756,
    alt: "sculpture of a cluster of leaves cast in bronze",
    title: "Silence of Dawn",
    description: "Bronze",
    info: null,
    dimensions: "11in x 8in x 1in"
  },
  {
    image: img_4761,
    alt: "sculpture of a leaf cast in bronze",
    title: "A Sea Before You",
    description: "Bronze",
    info: null,
    dimensions: "9in x 3in x 1in"
  },
  {
    image: img_4763,
    alt: "floral sculpture cast in bronze",
    title: "Lilies Dipping Sleep Beneath Moonlight",
    description: "Bronze",
    info: null,
    dimensions: "16in x 7in x 8in"
  },
  {
    image: img_4764,
    alt: "floral sculpture cast in bronze",
    title: "Under the Spell of Stars",
    description: "Bronze",
    info: null,
    dimensions: "11in x 5in x 7in"
  },
  {
    image: img_4765,
    alt: "abstract sculpture cast in bronze",
    title: "Web Adrift in the Sea of Time",
    description: "Bronze",
    info: null,
    dimensions: "12in x 11in x 1in"
  },
  {
    image: img_4767,
    alt: "abstract sculpture cast in bronze",
    title: "Web of Existence",
    description: "Bronze",
    info: null,
    dimensions: "13in x 14in x 4in"
  },
  {
    image: img_4770,
    alt: "abstract sculpture cast in bronze",
    title: "Web of Dreams",
    description: "Bronze",
    info: null,
    dimensions: "5in x 5in"
  },
];

export default projects;
