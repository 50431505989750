import React from "react";
import "./HeaderNav.css";
import { Link } from "react-router-dom";

function HeaderNav() {
  return (
    <header className="flex justify-between items-center p-4 max-w-full">
      <div className="headerTitle flex flex-col items-start ml-6">
        <h1 className="title">Candace Greenburg</h1>
        <h3>Local Artist - Tucson, AZ</h3>
      </div>
      <div className="navBtns flex justify-evenly items-center w-1/2 ">
        <Link to="/">
          <button className="navBtn" aria-label="about the collection">
            About
          </button>
        </Link>
        <Link to="/gallery">
          <button className="navBtn" aria-label="gallery of work">
            Gallery
          </button>
        </Link>
        <Link to="/contact">
          <button className="navBtn" aria-label="contact the artist">
            Contact
          </button>
        </Link>
      </div>
    </header>
  );
}

export default HeaderNav;
